import "./Infopage.css";

const Infopage = (props) => {

    const speedDial = (predefined) => {
        props.setSearch(predefined)
    }
    return (
        <div className="welcome">
            <div className="info">
                <p>Etsi mielenkiintoiset artikkelit ja tutki ilmiöiden sekä hahmojen
                trendejä isoimpien medioiden uutisvirrasta.</p>
                <p>Palvelu on maksuton ja avoin kaikille käytettäväksi.</p>
                <p className="small">Lähteet: Helsingin Sanomat, Iltalehti, Ilta-Sanomat ja Yle</p>

            </div>
            <div className="suggestions">
                <span className="examples">Esimerkkejä</span>
                <button onClick={() => speedDial('Sanna Marin')}>Sanna Marin</button>
                <button onClick={() => speedDial('Trump')}>Trump</button>
                <button onClick={() => speedDial('Biden')}>Biden</button>
                <button onClick={() => speedDial('Putin')}>Putin</button>
                <button onClick={() => speedDial('Zelenskyi')}>Zelenskyi</button>
                <button onClick={() => speedDial('Lukashenka')}>Lukashenka</button>
                <button onClick={() => speedDial('Elon Musk')}>Elon Musk</button>
                <button onClick={() => speedDial('korona')}>korona</button>
                <button onClick={() => speedDial('maastopalo')}>maastopalo</button>
                <button onClick={() => speedDial('Vastaamo')}>Vastaamo</button>
                <button onClick={() => speedDial('lakko')}>lakko</button>
                <button onClick={() => speedDial('Nato')}>Nato</button>
                <button onClick={() => speedDial('Venäjä')}>Venäjä</button>
                <button onClick={() => speedDial('Ukraina')}>Ukraina</button>
                <button onClick={() => speedDial('Kiina')}>Kiina</button>
                <button onClick={() => speedDial('Huuhkajat')}>Huuhkajat</button>
                <button onClick={() => speedDial('Helmarit')}>Helmarit</button>
                <button onClick={() => speedDial('Leijonat')}>Leijonat</button>
                <button onClick={() => speedDial('Susijengi')}>Susijengi</button>
                <button onClick={() => speedDial('Barkov')}>Barkov</button>
                <button onClick={() => speedDial('Markkanen')}>Markkanen</button>
                <button onClick={() => speedDial('Joulu')}>Joulu</button>
                <button onClick={() => speedDial('Juhannus')}>Juhannus</button>
                <button onClick={() => speedDial('Olympiala')}>Olympialaiset</button>
                <button onClick={() => speedDial('nainen')}>nainen</button>
                <button onClick={() => speedDial('mies')}>mies</button>
                <button onClick={() => speedDial('Ullanlinna')}>Ullanlinna</button>
            </div>
        </div>
    );
};
export default Infopage;
