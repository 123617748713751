import "./HeadlineItem.css";
import moment from "moment";
import Highlighter from "react-highlight-words";

const HeadlineItem = (props) => {

  const finnishWeekDay = (num) => {
    switch (num) {
      case 0:
        return "SU";
        break;
      case 1:
        return "MA";
        break;
      case 2:
        return "TI";
        break;
      case 3:
        return "KE";
        break;
      case 4:
        return "TO";
        break;
      case 5:
        return "PE";
        break;
      case 6:
        return "LA";
    }
  }
  return (
    <div className={`headline-item ${props.sameDay ? "grouped" : ""}`}>
      <div className="date">
        <span className="wd">{finnishWeekDay(moment(props.date).day())}</span>
        <span className="dm">{moment(props.date).format("D.M.")}</span>
        <span className="y">{moment(props.date).format("YYYY")}</span>
      </div>
      <div className="headline">
        <Highlighter
          highlightClassName="shl"
          searchWords={props.highlight}
          autoEscape={true}
          textToHighlight={props.headline}
        />
      </div>
        
      <div className="link">
        <a href={props.link} target="_blank" rel="noreferrer">
          {props.headline}
          {props.source == 'YL' && <img src="/Yle.png" alt="Yle logo" title="Avaa Yle artikkeli" /> }
          {props.source == 'HS' && <img src="/HS.png" alt="Helsingin Sanomat logo" title="Avaa HS artikkeli" /> }
          {props.source == 'IL' && <img src="/IL.png" alt="Iltalehti logo" title="Avaa IL artikkeli" /> }
          {props.source == 'IS' && <img src="/IS.png" alt="Ilta-Sanomat logo" title="Avaa IS artikkeli" /> }
        </a>
      </div>
      {props.sameDay}
    </div>
  );
};
export default HeadlineItem;
