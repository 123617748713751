import React, { useState } from "react";
import axios from "axios";
import HeadlineItem from "./components/HeadlineItem";
import LineChart from "./components/LineChart";
import Infopage from "./components/Infopage";
import "./App.css";
import SearchInput from "./components/SearchInput";
import { DebounceInput } from 'react-debounce-input';

const App = () => {
  const [data, setData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [grouped, setGrouped] = useState([]);
  const [search, setSearch] = useState("");
  const [lastSearch, setLastSearch] = useState("");
  const [lastSuccesfulSearch, setLastSuccesfulSearch] = useState("");
  const [lastGrouped, setLastGrouped] = useState([]);
  const [noResults, setNoResults] = useState("");
  const [badSearch, setBadSearch] = useState("");
  const [browseFromDate, setBrowseFromDate] = useState("");
  const [filter, setFilter] = useState("");
  const [reversed, setReversed] = useState(true);

  const url = (query) => `https://jonikiiskinen.com/otsikoissa/api2.php?search=${query}`;

  const handleChangedSearch = (newValidSearch) => {
    setSearch(newValidSearch);
    setBrowseFromDate("");
    searchHeadline(newValidSearch);
    setFilter("");
  }

  const searchHeadline = (query) => {
    setNoResults("");
    axios.get(url(query)).then((response) => {
      setBadSearch("")
      if (!response.data.message) {
        setLastSuccesfulSearch({ 'name': lastSearch, 'qty': data.length });
        setLastGrouped(grouped);
        setPrevData(data);
        setData(response.data.data);
        groupByDay(response.data.data);
        setLastSearch(query);
      } else {
        // setLastSearch(search);
        setNoResults(query);
      }
    });
  };

  const groupByDay = (data) => {
    var counts = data.reduce((p, c) => {
      var label = c.pvm;
      if (!p.hasOwnProperty(label)) {
        p[label] = 0;
      }
      p[label]++;
      return p;
    }, {});

    var countsExtended = Object.keys(counts).map((k) => {
      return { label: k, count: counts[k] };
    });
    setGrouped(countsExtended);
  };

  let highlight = lastSearch ? lastSearch.split(' ') : '';
  lastSearch && filter.length > 0 && highlight.push(filter);

  let filteredResult = data.filter((story) =>
    story.headline.toLowerCase().includes(filter)
  );

  const searchResultHeadlines = filteredResult.map((story, index, arr) => {
    const nextOrPrev = reversed ? index + 1 : index - 1;
    const prevStory = arr[nextOrPrev];
    const same = prevStory?.pvm === story.pvm

    return (
      <HeadlineItem
        key={story.id}
        date={story.pvm}
        sameDay={same}
        source={story.src}
        headline={story.headline}
        link={story.link}
        highlight={highlight}
      />
    );
  });

  const toComparableDate = (dateString) => dateString.replace(/-/g, "");

  const dateFiltered = searchResultHeadlines.filter(
    (story) =>
      toComparableDate(story.props.date) >= toComparableDate(browseFromDate)
  );

  const limitedResults = reversed ? dateFiltered.reverse().slice(0, 50) : dateFiltered.slice(0, 50);

  const removeLast = () => {
    setData([])
    setData(prevData)
    setGrouped(lastGrouped)
    setLastSearch(lastSuccesfulSearch.name)
    setLastSuccesfulSearch("")
    setLastGrouped([])
    setPrevData([])
    setLastSuccesfulSearch("")
    setLastGrouped([])
    setFilter("");
  };
  const removeHistory = () => {
    setPrevData([])
    setLastSuccesfulSearch("")
    setLastGrouped([])
  };

  const buttonHandler = () => {
    setReversed(current => !current)
  }

  return (
    <div className="App">
      <div className="container">
        <div className="header">
          <div className="left">
            <a href="" rel="noreferrer">
              <h1 className={`${data && data.length > 0 ? "running" : ""}`}>OTSIKOISSA</h1>
            </a>
          </div>
          <div className="center">

            <SearchInput
              setSearch={handleChangedSearch}
              setBadSearch={setBadSearch}
              setBrowseFromDate={setBrowseFromDate}
              searchHeadline={searchHeadline}
              badSearch={badSearch}
              noResults={noResults}
              lastSearch={lastSearch}
            />

            {lastSearch && (
              <div className="last">
                <div className="lastForm">
                  <div className="lastInput">
                    <span><b>{lastSearch}</b> {data && data.length > 0 && data.length} kpl</span>
                    <button onClick={removeLast}>&#x2716;</button>
                  </div>
                </div>
              </div>
            )}

            {lastSuccesfulSearch.name && (
              <div className="history">
                <div className="historyForm">
                  <div className="historyInput">
                    <span><b>{lastSuccesfulSearch.name}</b> {lastSuccesfulSearch.qty} kpl</span>
                    <button onClick={removeHistory}>&#x2716;</button>
                  </div>
                </div>
              </div>
            )}

          </div>

          <div className="right">
          </div>

        </div>

        <div className="content">
          <div className="chart">
            {data && data.length > 0 && (
              <LineChart
                chartData={grouped}
                prevChartData={lastGrouped}
                setBrowseFromDate={setBrowseFromDate}
                browseFromDate={browseFromDate}
                lastSearch={lastSearch}
                historySearch={lastSuccesfulSearch.name}
              />
            )}
          </div>





          {data && data.length > 0 &&
            <div className="newsTable">
              <div className="filters">
                <div className="filter">
                <DebounceInput
                  minLength={2}
                  value={filter}
                  debounceTimeout={300}
                  placeholder="Suodata tuloksista"
                  className="filterInput"
                  onChange={event => setFilter(event.target.value)} />
                <span className="filteredAmount">{filteredResult.length} kpl</span>
                </div>
                <button onClick={buttonHandler}>{reversed ? "uusin ylimpänä" : "vanhin ylimpänä"}</button>
              </div>
              {limitedResults}
            </div>
          }
          {data && data.length === 0 &&
            <div className="infoTable">
              {data.length == 0 && <Infopage setSearch={handleChangedSearch} />}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default App;
