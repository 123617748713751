import React from "react";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import "./LineChart.css";

ChartJS.register(
  CategoryScale,
  LineElement,
  PointElement,
  LinearScale,
  Tooltip,
  Title,
  Legend
);

const LineChart = (props) => {
  const generateChart = (data, prev) => {
    //-----------SEARCH

    let labels = data?.map((x) => x.label);
    let graphData = data?.map((x) => x.count);
    let prevLabels = prev?.map((x) => x.label);
    let prevGraphData = prev?.map((x) => x.count);

    // CHART BEGIN DATE
    let dateStart = "2019-10-23";
    // Make labels start from start date
    labels.unshift(dateStart);
    prevLabels.unshift(dateStart);
    // Push empty value at the beginning of data
    graphData.unshift("NULL");
    prevGraphData.unshift("NULL");

    // CHART END DATE (tomorrow)
    let dateEnd = moment().add(1, "day").format("YYYY-MM-DD");
    // Make labels end tomorrow
    labels.push(dateEnd);
    prevLabels.push(dateEnd);
    // Push empty values at the end of data
    graphData.push("NULL");
    prevGraphData.push("NULL");

    // FILL IN EMPTY DATES SEARCH
    for (var i = 0; i < labels.length; i++) {
      //make sure we are not checking the last date in the labels array
      if (i + 1 < labels.length) {
        var date1 = moment(labels[i], "YYYY-MM-DD"); // First Day Of Hits
        var date2 = moment(labels[i + 1], "YYYY-MM-DD"); // Last Day Of Hits

        //if the current date +1 is not the same as it's next neighbor we have to add in a new one
        if (!date1.add(1, "days").isSame(date2)) {
          //add the label
          labels.splice(i + 1, 0, date1.format("YYYY-MM-DD"));
          //add the data
          graphData.splice(i + 1, 0, "NULL");
        }
      }
    }

    // Prev 
    for (var i = 0; i < prevLabels.length; i++) {
      //make sure we are not checking the last date in the prevLabels array
      if (i + 1 < prevLabels.length) {
        var date1 = moment(prevLabels[i], "YYYY-MM-DD"); // First Day Of Hits
        var date2 = moment(prevLabels[i + 1], "YYYY-MM-DD"); // Last Day Of Hits

        //if the current date +1 is not the same as it's next neighbor we have to add in a new one
        if (!date1.add(1, "days").isSame(date2)) {
          //add the label
          prevLabels.splice(i + 1, 0, date1.format("YYYY-MM-DD"));
          //add the data
          prevGraphData.splice(i + 1, 0, "NULL");
        }
      }
    }

    const toComparableDate = dateString => dateString.replace(/-/g, "");

    let activePoints = []
    labels.forEach(label => {
      if (toComparableDate(label) >= toComparableDate(props.browseFromDate)) {
        activePoints.push("#00b4c8")
      } else {
        activePoints.push("#ccc")
      }
    })




    let newData = {
      labels: labels,
      datasets: [
        {
          data: graphData,
          label: "Otsikoita",
          backgroundColor: activePoints,
          borderColor: "transparent",
          pointRadius: 2,
          pointHoverRadius: 10,
        },
        {
          data: prevGraphData,
          label: "Edellinen haku",
          backgroundColor: "#ccc",
          borderColor: "transparent",
          pointRadius: 1,
          pointHoverRadius: 0,
        },
      ],
    };
    return newData;
  };

  let delayed;

  let options = {
    hoverBorderColor: "#000",
    interaction: {
      intersect: false,
      mode: "nearest",
    },
    maintainAspectRatio: false,
    onClick: function (evt, item) {
      // FILTER HEADLINES FROM CLICKED DAY ONWARDS
      props.setBrowseFromDate(evt.chart.data.labels[item[0].index])
    },
    'onHover': function (evt, item) {
      // props.setBrowseFromDate(evt.chart.data.labels[item[0].index])
      // console.log('legend onClick', evt);
      // console.log('legd item', item);
    },
    animation: {
      onComplete: () => {
        // delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default" && !delayed) {
          delay = context.dataIndex * 0.5;
        }
        return delay;
      },
    },
    plugins: {
      legend: false, // Hide legend

      tooltip: {
        enabled: true,
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: 20,
            yMax: 20,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
          },
        },
      },
    },

    scales: {
      y: {
        display: false, // Hide Y axis labels
      },
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (val, index) {
            // Hide the label of every 2nd dataset
            // return
            // console.log(this.getLabelForValue(val).substring(5))
            return this.getLabelForValue(val).substring(5) === "01-01"
              ? this.getLabelForValue(val).substring(4, 0)
              : "";
          },
          color: "#222",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="linechart">
      <Line
        data={generateChart(props.chartData, props.prevChartData)}
        options={options}
      />
    </div>
  );
};
export default LineChart;
