import React, { useState } from "react";

const SearchInput = (props) => {
  const [localSearch, setLocalSearch] = useState("");

  const lettersNumbersSpacesDashes = (str) => {
    return /^[A-Za-z0-9 -!?ÄäÖöÅå]*$/.test(str);
  }

  const searchHandler = (event) => {
    event.preventDefault();
    if (!lettersNumbersSpacesDashes(localSearch)) {
      return props.setBadSearch("Käytä vain kirjaimia")
    }
    if (localSearch.length < 3) {
      return props.setBadSearch("Kirjoita vähintään kolme merkkiä")
    }
    if (localSearch === props.lastSearch) return
    props.setSearch(localSearch)
    setLocalSearch("")
  };

  return (
    <form onSubmit={searchHandler} className={`searchForm ${props.badSearch || props.noResults ? "note" : ""}`}>
      <input
        value={localSearch}
        onChange={(event) => setLocalSearch(event.target.value)}
        placeholder="Hakusana"
        type="text"
        autoFocus
      />
      <div className="searchNotification">
        {props.badSearch && (
          <div className="badSearch">{props.badSearch}</div>
        )}
        {props.noResults && !props.badSearch && (
          <div className="noResultsText">Ei tuloksia hakusanalla "{props.noResults}"</div>
        )}

      </div>
    </form>
  );
};
export default SearchInput;
